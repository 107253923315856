import api from "@/base/utils/request";

// 获取查看列表
export const CheckList = data => {
  return api({
    url: "/admin/cyc/multi_level/relations",
    method: "post",
    data
  });
};

// 移除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/cyc/multi_level/deleteBrand",
    method: "post",
    data
  });
};
// 文章分类选项数据
export const articleCategorys = data => {
  return api({
    url: "/admin/portal/category/allShow",
    method: "post",
    data
  });
};